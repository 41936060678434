import {
	Button,
	Center,
	Flex,
	GridItem,
	Td,
	Text,
	useDisclosure,
} from '@chakra-ui/react'
import {
	AddressCell,
	AlertDialog,
	Table,
	Tile,
	Link,
	TileSaveButton,
	TileCancelButton,
	InputField,
	AddressFields,
	SwitchField,
	InputFieldFlex,
	SelectField,
	MaskedInputField,
	TextAreaField,
	useErrorToast,
} from '@kevea/react-components'
import axios, { AxiosResponse } from 'axios'
import { DisabilitySymbolsPicker } from 'components/DisabilitySymbolsPicker'
import { Form, Formik, FormikProps } from 'formik'
import { IRelatedPerson, Patient, RelatedPerson } from 'models/patient'
import { useMemo, useRef, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Column, Cell, useTable } from 'react-table'
import { PatientService } from 'services/patient'
import { PatientContext } from '../Patient'
import { RelatedPersonModal } from './RelatedPersonModal'

export const GeneralEdit = () => {
	const { patient, refetch, ...rest } = useOutletContext<PatientContext>()
	const [loading, setLoading] = useState(false)
	const removeRelatedPersonDisclosure = useDisclosure()
	const addRelatedPersonDisclosure = useDisclosure()
	const navigate = useNavigate()
	const [relatedPersons, setRelatedPersons] = useState<IRelatedPerson[]>(
		patient?.relatedPersons || [],
	)
	const error = useErrorToast()
	const [selectedPersonIndex, setSelectedPersonIndex] = useState<
		number | undefined
	>()

	const handleError = (e: any) => {
		if (axios.isAxiosError(e)) {
			const res = e.response?.data
			if (res.errors && Array.isArray(res.errors)) {
				if (res.errors.some((e: any) => e.name === 'maxPatients')) {
					error({
						description:
							'Maksymalna liczba pacjentów została przekroczona, skontaktuj się z nami w celu zwiększenia limitu pacjentów.',
						duration: 15000,
					})
				}
			}
		}
		setLoading(false)
	}

	const [editablePatient, setEditablePatient] = useState(new Patient(patient))

	const formik = useRef<FormikProps<Patient>>(null)

	const handleSubmit = (values: Patient) => {
		const afterSubmit = (response?: AxiosResponse<Patient>) => {
			setLoading(false)
			navigate('/patients/' + response?.data?._id ?? '')
		}
		setLoading(true)
		const newPatient: Patient = {
			...values,
			relatedPersons,
			disability: values.disability.level ? values.disability : null,
		}
		if (patient?._id) {
			PatientService.put(newPatient)
				.then(patient => {
					refetch?.()
					return patient
				})
				.then(afterSubmit)
				.catch(handleError)
		} else {
			PatientService.post(newPatient).then(afterSubmit).catch(handleError)
		}
	}

	const columns = useMemo<Column<IRelatedPerson>[]>(
		() => [
			{
				Header: 'Imię i Nazwisko',
				accessor: 'name',
				Cell: cell => (
					<Link
						onClick={() => {
							setSelectedPersonIndex(cell.row.index)
							addRelatedPersonDisclosure.onOpen()
						}}
					>
						{cell.value}
					</Link>
				),
			},
			{
				Header: 'Numer Telefonu',
				accessor: 'phoneNumber',
			},
			{
				Header: 'E-Mail',
				accessor: 'emailAddress',
			},
			{
				Header: 'Adres',
				accessor: 'address',
				Cell: AddressCell,
			},
			{
				Header: 'Relacja',
				accessor: 'relation',
				Cell: cell => <Text>{RelatedPerson.convertRelation(cell.value)}</Text>,
			},
			{
				id: 'remove',
				accessor: 'name',
				width: 50,
				Cell: cell => (
					<Link
						onClick={() => {
							setSelectedPersonIndex(cell.row.index)
							removeRelatedPersonDisclosure.onOpen()
						}}
						color="red.600"
					>
						Usuń
					</Link>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	const tableInstance = useTable({ data: relatedPersons, columns: columns })

	return (
		<Formik
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={handleSubmit}
			initialValues={editablePatient}
			innerRef={formik}
			validationSchema={Patient.validationSchema}
		>
			{({ values }) => (
				<Form>
					<Tile
						grid
						{...rest}
						titleElement={null}
						headerButtons={
							<>
								<TileSaveButton
									onSave={() => formik.current?.submitForm()}
									isLoading={loading}
								/>
								<TileCancelButton
									isLoading={loading}
									onCancel={() =>
										patient?._id
											? navigate('/patients/' + patient._id)
											: navigate('/patients')
									}
								/>
							</>
						}
					>
						<GridItem colSpan={{ base: 2, lg: 1 }}>
							<InputFieldFlex>
								<InputField
									name="firstName"
									placeholder="Imię"
									isRequired
									label="Imię"
								/>
								<InputField
									name="lastName"
									placeholder="Nazwisko"
									isRequired
									label="Nazwisko"
								/>
							</InputFieldFlex>
							<SelectField
								label="Status"
								options={Patient.statusOptions}
								name="status"
								onChange={status =>
									setEditablePatient({ ...editablePatient, status })
								}
							/>
							<SelectField
								label="Płeć"
								name="sex"
								options={Patient.sexOptions}
								onChange={sex =>
									setEditablePatient({ ...editablePatient, sex })
								}
							/>
							<SelectField label='Stan cywilny' name='maritalStatus' options={Patient.maritalStatusOptions} />
							<InputField
								label='Nazwisko rodowe'
								name='familyName'
							/>
							<InputField
								label='Imię i nazwisko ojca'
								name='fatherFullName'
							/>
							<InputField
								label='Imię i nazwisko matki'
								name='motherFullName'
							/>
							{editablePatient.status === 2 && (
								<InputField
									name="dateOfDischarge"
									placeholder="Data Wypisu"
									label="Data Wypisu"
									type="date"
								/>
							)}
							{editablePatient.status === 3 && (
								<InputField
									name="dateOfDeath"
									placeholder="Data Zgonu"
									label="Data Zgonu"
									type="date"
								/>
							)}
							<Flex gap={2} justifyContent="space-between" alignItems="end">
								<InputField
									name="dateOfBirth"
									placeholder="Data Urodzenia"
									label="Data i Miejsce Urodzenia"
									type="date"
								/>
								<InputField name="birthPlace" placeholder="Miejsce urodzenia" />
							</Flex>
							<InputField
								name="admissionDate"
								placeholder="Data Przyjęcia"
								label="Data Przyjęcia"
								type="date"
							/>

							<AddressFields
								accessor="previousAddress"
								label="Poprzedni Adres Zamieszkania"
								copy={{
									from: 'placeOfResidence',
									label: 'kopiuj z zameldowania',
								}}
							/>
							<AddressFields
								accessor="placeOfResidence"
								label="Adres Zameldowania"
								copy={{
									from: 'previousAddress',
									label: 'kopiuj z zamieszkania',
								}}
							/>

							<TextAreaField
								name="medicalRecommendations"
								label="Zalecenia lekarskie"
							/>
							<TextAreaField name="notes" label="Inne informacje" />
						</GridItem>
						<GridItem colSpan={{ base: 2, lg: 1 }}>
							<InputField
								name="room"
								label="Numer Pokoju"
								placeholder="Numer Pokoju"
							/>
							<InputField name="pesel" label="PESEL" placeholder="PESEL" />
							<InputFieldFlex>
								<MaskedInputField
									mask="aaa000000"
									name="idCardNumber"
									label="Numer dowodu osobistego"
									placeholder="AAA999999"
								/>
								<InputField
									name="idIssuer"
									placeholder="Organ wydający"
									label="Organ wydający"
								/>
							</InputFieldFlex>
							<InputField
								name="idExpiryDate"
								placeholder="Data ważności dowodu osobistego"
								label="Data ważności dowodu osobistego"
								type="date"
							/>
							<InputField name="nip" label="NIP" placeholder="NIP" />
							<InputField
								name="phoneNumber"
								label="Numer telefonu"
								placeholder="Numer telefonu"
							/>
							<InputField
								name="zusNumber"
								label="Numer ZUS"
								placeholder="Numer ZUS"
							/>
							<SwitchField
								name="incapacitated"
								label="Ubezwłasnowolniony"
								onChange={incapacitated => {
									setEditablePatient({
										...editablePatient,
										incapacitated,
									})
								}}
							/>
							{editablePatient.incapacitated && (
								<InputField
									name="dateOfIncapacitation"
									placeholder="Data Ubezwłasnowolnienia"
									label="Data Ubezwłasnowolnienia"
									type="date"
								/>
							)}
							<SelectField
								label="Stopień Niepełnosprawności"
								options={Patient.disablityLevelOptions}
								name="disability.level"
							/>
							<DisabilitySymbolsPicker
								name="disability.symbols"
								hidden={!values.disability.level}
							/>
							<SwitchField
								name="incapableOfIndependentExistence"
								label="Niezdolność do samodzielnej egzystencji"
							/>
							<SwitchField
								name="requireDiapersOrder"
								label="Wymagaj Zlecenia Na Pieluchomajtki"
							/>
						</GridItem>
						<GridItem colSpan={2}>
							<RelatedPersonModal
								relatedPersons={relatedPersons}
								setRelatedPersons={setRelatedPersons}
								selectedPersonIndex={selectedPersonIndex}
								onClose={addRelatedPersonDisclosure.onClose}
								isOpen={addRelatedPersonDisclosure.isOpen}
							/>
							<AlertDialog
								header="Usuń Osobę Powiązaną"
								onClose={removeRelatedPersonDisclosure.onClose}
								isOpen={removeRelatedPersonDisclosure.isOpen}
								onRemove={() => {
									if (selectedPersonIndex !== undefined) {
										setRelatedPersons(
											relatedPersons.filter(
												(_, idx) => idx !== selectedPersonIndex,
											),
										)
										setSelectedPersonIndex(undefined)
										removeRelatedPersonDisclosure.onClose()
									}
								}}
							>
								Czy chcesz usunąć{' '}
								<strong>
									{selectedPersonIndex !== undefined &&
										relatedPersons[selectedPersonIndex]?.name}
								</strong>{' '}
								z listy osób powiązanych pacjenta?
							</AlertDialog>
							<Table
								hideNumberOfRows
								tableInstance={tableInstance}
								footer={
									<Td colSpan={tableInstance.visibleColumns.length}>
										<Center>
											<Button
												size="sm"
												colorScheme="add"
												onClick={() => {
													setSelectedPersonIndex(undefined)
													addRelatedPersonDisclosure.onOpen()
												}}
											>
												Dodaj
											</Button>
										</Center>
									</Td>
								}
							/>
						</GridItem>
					</Tile>
				</Form>
			)}
		</Formik>
	)
}
