import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import {
	TableWarehouseDocument,
	WarehouseDocument,
} from 'models/warehouseDocument'
import { WarehouseDocumentService } from 'services/warehouseDocument'
import { useDisclosure } from '@chakra-ui/react'
import {
	Column,
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import {
	Link,
	SelectFilter,
	Table,
	Tile,
	TileGrid,
} from '@kevea/react-components'
import { FaBoxes } from 'react-icons/fa'
import { Badge } from 'components/StatusBadge'
import { useNavigate } from 'react-router'

export type WarehouseDocumentListProps = {}

export const WarehouseDocumentList = (props: WarehouseDocumentListProps) => {
	const [loading, setLoading] = useState(false)
	const {
		data: warehouseDocuments,
		isLoading,
		isRefetching,
		refetch,
	} = useQuery(
		'fetchWarehouseDocuments',
		WarehouseDocument.fetchWarehouseDocuments,
	)
	const openDisclosure = useDisclosure()

	const data = useMemo(
		() =>
			warehouseDocuments?.map(order => new TableWarehouseDocument(order)) ?? [],
		[warehouseDocuments],
	)

	const navigate = useNavigate()

	const columns = useMemo<Column<TableWarehouseDocument>[]>(
		() => [
			{
				Header: 'Numer',
				accessor: 'number',
				Cell: ({ row, value }) => (
					<Link to={row.original.original._id}>{value}</Link>
				),
			},
			{
				Header: 'Status',
				accessor: 'status',
				Filter: ({ column }) => (
					<SelectFilter
						column={column}
						options={TableWarehouseDocument.statusOptions}
					/>
				),
				Cell: ({ row }) => <Badge warehouseDocument={row.original.original} />,
			},
		],
		[],
	)
	const tableInstance = useTable(
		{ columns: columns, data: data },
		useGlobalFilter,
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
	)
	return (
		<TileGrid>
			<Tile headerIcon={<FaBoxes />} header="Dokumenty magazynowe">
				<Table
					tableInstance={tableInstance}
					loading={isLoading || loading || isRefetching}
					buttons={[{ label: 'Dodaj', type: 'add', to: 'new' }]}
					menuOptions={{
						noSelection: [
							{
								label: 'Wygeneruj PL automatycznie',
								onClick: () => {
									navigate('/warehouseDocuments/new?autofill=true')
								},
							},
						],
						singleSelection: [
							{
								label: 'Potwierdź',
								onClick: () => {
									if (tableInstance.selectedFlatRows[0].original.original) {
										setLoading(true)
										WarehouseDocumentService.put({
											...tableInstance.selectedFlatRows[0].original.original,
											status: 'completed',
										}).then(() => {
											setLoading(false)
											refetch()
										})
									}
								},
								disabled:
									tableInstance.selectedFlatRows[0]?.original.original
										.status !== 'new',
							},
							{
								label: 'Cofnij status',
								onClick: () => {
									if (tableInstance.selectedFlatRows[0].original.original) {
										setLoading(true)
										WarehouseDocumentService.put({
											...tableInstance.selectedFlatRows[0].original.original,
											status: 'new',
										}).then(() => {
											setLoading(false)
											refetch()
										})
									}
								},
								disabled:
									tableInstance.selectedFlatRows[0]?.original.original
										.status === 'new',
							},
							{
								label: 'Usuń',
								onClick: () => {
									if (tableInstance.selectedFlatRows[0].original.original) {
										setLoading(true)
										WarehouseDocumentService.delete(
											tableInstance.selectedFlatRows[0].original.original._id,
										).then(() => {
											setLoading(false)
											refetch()
										})
									}
								},
								disabled:
									tableInstance.selectedFlatRows[0]?.original.original
										.status !== 'new',
							},
							{
								label: 'Wygeneruj PL automatycznie',
								onClick: () => {
									navigate('/warehouseDocuments/new?autofill=true')
								},
							},
						],
						multipleSelection: [
							{
								label: 'Wygeneruj PL automatycznie',
								onClick: () => {
									navigate('/warehouseDocuments/new?autofill=true')
								},
							},
						],
					}}
				/>
			</Tile>
		</TileGrid>
	)
}
